<template>
  <div class="card" style="border: none">
    <div class="card-body" :style="isPrint ? `height: ${height}; width: ${width};` : ''">
      <div class="row justify-content-between align-items-center">
        <div class="col-md-12 text-center">
          <ImageComponent place="logo-invoice"></ImageComponent>
          <div class="font-weight-light" style="font-size: 10pt">
            {{ setting.clinic_address }}
          </div>
          <div class="font-weight-light" style="font-size: 10pt">
            {{ setting.phone }}
          </div>
        </div>

        <div class="col-12">
          <!-- <span>====================</span> -->
          <div class="my-2 w-100 text-muted" style="border-top: 2px dashed"></div>
          <table style="font-size: 10pt !important">
            <tr>
              <td style="width: 100px">Nomor</td>
              <td style="width: 9px">:</td>
              <td>{{ data.id }}</td>
            </tr>
            <tr>
              <td>Tanggal</td>
              <td style="width: 9px">:</td>
              <td>{{ data.display_created_at }}</td>
            </tr>
            <tr>
              <td>Nama Pasien</td>
              <td style="width: 9px">:</td>
              <td>{{ data.patient_name }}</td>
            </tr>
            <tr v-if="data.sub_patient_id != null">
              <td>Nama Sub Pasien</td>
              <td style="width: 9px"> : </td>
              <td> {{ data.sub_patient_name }}</td>
            </tr>
            <tr>
              <td>Nomor HP</td>
              <td style="width: 9px">:</td>
              <td>{{ data.patient_mobile_phone }}</td>
            </tr>
            <tr>
              <td>Dokter</td>
              <td style="width: 9px">:</td>
              <td>{{ data.doctor_name }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- <span>====================</span> -->
      <div class="my-2 w-100 text-muted" style="border-top: 2px dashed"></div>
      <div style="font-size: 10pt">
        <div class="row">
          <!-- item -->
          <div class="col-md-12" v-for="(data, index) in items" :key="index">
            <span class="d-block">{{ data.name }}</span>
            <div class="row px-3 py-1 justify-content-between">
              <span class="pl-3">{{ data.quantity }} x
                {{ parseInt(data.price - data.promo_amount + data.discount_amount).toLocaleString("id-ID") }}</span>
              <span>
                {{ parseInt((data.price - data.promo_amount + data.discount_amount) *
                  data.quantity).toLocaleString("id-ID") }}
              </span>
            </div>
            <div class="row px-3 py-1 justify-content-end" v-if="data.discount_amount != 0">
              <span>Disc. {{ parseInt(data.discount_amount).toLocaleString("id-ID") }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div style="min-height: 285px; font-size: 7pt">
        <div class="row">
          <div class="col-md-12">
            <b-table borderless small :items="items" :fields="fields" style="font-size: 8pt !important">
              <template #table-colgroup="scope">
                <col v-for="field in scope.fields" :key="field.key" />
              </template>
              <template #cell(price)="data">
                {{
                  "Rp " +
                  parseInt(
                    parseInt(data.item.price) + data.item.discount_amount
                  ).toLocaleString("id-ID")
                }}
              </template>
              
              <template #cell(subtotal)="data">
                <span class="d-block">
                  {{
                    "Rp " +
                    parseInt(
                      data.item.price * data.item.quantity
                    ).toLocaleString("id-ID")
                  }}
                </span>
                <span class="d-block" v-if="data.item.discount_amount != 0"
                  >Disc : Rp
                  {{
                    `${parseInt(data.item.discount_amount).toLocaleString(
                      "id-ID"
                    )} ${
                      data.item.discount_type == 1 &&
                      (data.item.discount_amount != 0 ||
                        data.item.discount_percentage != 0)
                        ? `(${parseInt(data.item.discount_percentage)})%`
                        : ""
                    }`
                  }}</span
                >
              </template>
            </b-table>
          </div>
        </div>
      </div> -->
      <!-- <span>====================</span> -->
      <div class="my-2 w-100 text-muted" style="border-top: 2px dashed"></div>
      <div class="row" style="font-size: 10pt">
        <div class="col-md-12 py-0">
          <span class="font-weight-bold d-block">Catatan :</span>
        </div>
        <div class="col-md-12 pt-1">
          <span>{{ data.notes }}</span>
        </div>
      </div>
      <!-- <span>====================</span> -->
      <div class="my-2 w-100 text-muted" style="border-top: 2px dashed"></div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="mr-2">
          <!-- Notes -->
          <!-- <div
            class="form-group"
          >
            <label for="notes">Catatan:</label>
            <textarea
              name=""
              id="notes"
              cols="30"
              rows="3"
              class="form-control"
              style="resize: none"
              v-model="data.notes"
              readonly
            ></textarea>
          </div> -->
        </div>
        <table v-if="showSummary" style="font-size: 10pt !important">
          <tr>
            <td style="width: 150px">Subtotal</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.total_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>Diskon</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.discount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>Biaya Tambahan</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.surcharge).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr v-if="data.coupon_amount">
            <td>Kupon</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.coupon_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.final_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- <div class="d-flex justify-content-center p-0 mt-n3">
      <div>
        <hr class="special-hr" style="margin: 2px" />
        <div>{{ currentUserPersonalInfo.name }}</div>
      </div>
    </div> -->
    <!-- <div class="d-flex justify-content-center p-0 mt-n3">
      <div>
        <hr class="special-hr" style="margin: 2px" />
        <div>{{ currentUserPersonalInfo.name }}</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ImageComponent from "@/component/general/ImageComponent.vue";

export default {
  props: {
    data: Object,
    height: String,
    width: String,
    isPrint: Boolean,
    setting: Object,
    items: Array,
    renderNotes: Boolean,
    showSummary: Boolean,
    notes: String,
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    mutated_total_amount: {
      set(newValue) {
        this.data.total_amount = newValue;
      },
      get() {
        let amount = this.data.total_amount;
        this.items.forEach((value) => {
          amount += value.discount_amount * value.quantity;
        });
        return amount;
      },
    },

    mutated_final_amount: {
      set(newValue) {
        this.data.final_amount = newValue;
      },
      get() {
        let amount = this.data.final_amount;
        this.items.forEach((value) => {
          amount += value.discount_amount * value.quantity;
        });
        return amount;
      },
    },
  },

  components: {
    ImageComponent,
  },

  data() {
    return {
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Ket",
        },
        {
          key: "quantity",
          label: "Jml",
        },

        {
          key: "price",
          label: "Nett",
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "subtotal",
          label: "Subtotal",
          thClass: "text-right",
          tdClass: "text-right",
        },
      ],
    };
  },

  mounted() {
    console.log(this.data, "notes");
  },
};
</script>

<style scoped>
.special-hr {
  border-color: #000000;
  border-style: solid;
  border-width: 2px 0 0;
  clear: both;
  margin: 0 0 0px;
  height: 0;
}
</style>